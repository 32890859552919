<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

//COMPONENTS
import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Modal from "@/components/Modal.vue";

export default {
	components: {
		Footer,
		NavBarVotacion,
		ScrollButton,
		Modal,
	},
	data() {
		return {
			listed: false,
			user: {},
			rank: {},
			votes: [],
			cultures: [],
			modal: {
				title: null,
				message: null,
				show: false,
			},
		};
	},
	created() {
		this.listed = false;
		this.loadUser();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",

			//READ_USER_INFO
			readUser: "auth/readUser",
			readRank: "getRank/readRank",
			readVotes: "getVotes/readVotes",

			//READ_CULTURES
			readCultures: "getCultures/readCultures",

			//READ_ERRORS
			getUserError: "auth/readError",
			getRankError: "getRank/readError",
			getVotesError: "getVotes/readError",
			getCulturesError: "getCultures/readError",
			updatePhotoError: "updatePhoto/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",

			//SET_USER_ID
			setRankId: "getRank/SET_ID",
			setVotesId: "getVotes/SET_ID",

			//UPDATE
			setPhoto: "updatePhoto/SET_PHOTO",

			//SET_ERRORS
			setUserError: "auth/SET_ERROR",
			setRankError: "getRank/SET_ERROR",
			setVotesError: "getVotes/SET_ERROR",
			setCulturesError: "getCultures/SET_ERROR",
			setPhotoError: "updatePhoto/SET_ERROR",
		}),
		...mapActions({
			//USER_INFO
			getUser: "auth/getUser",
			getRank: "getRank/getRank",
			getVotes: "getVotes/getVotes",

			//UPDATE
			updatePhoto: "updatePhoto/updatePhoto",

			//CULTURES
			getCultures: "getCultures/getCultures",
		}),
		orderedArray(arr) {
			return arr.slice().sort((a, b) => {
				return b.count - a.count;
			});
		},
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadUser() {
			this.setLoading(true);
			this.clearErrors();
			this.getUser()
				.then()
				.finally(() => {
					this.user = this.readUser;
					this.loadVotes();
				});
		},
		loadRank() {
			this.rank = {
				votes: this.readVotes.length,
				rank: "-",
			};
		},
		loadVotes() {
			this.clearErrors();
			this.setVotesId(this.user.id);
			this.getVotes()
				.then((result) => {
					if (result) {
						if (this.readVotes.length > 0) {
							this.votes = this.readVotes;
						} else {
							this.votes = [];
						}
					}
				})
				.finally(() => {
					this.loadRank();
					this.loadCultures();
				});
		},
		loadCultures() {
			this.clearErrors();
			this.getCultures()
				.then((result) => {
					if (result) {
						this.cultures = this.readCultures;
						this.calcPercent();
					}
				})
				.finally(() => {
					this.listed = true;
					this.setLoading(false);
				});
		},
		loadImage(e) {
			this.setLoading(true);
			if (e) {
				this.clearErrors();
				this.setPhoto(e.target.files[0]);
				this.updatePhoto()
					.then((result) => {
						if (result) {
							this.loadUser();
						}
					})
					.finally(() => {
						this.setLoading(false);
					});
			}
		},
		calcPercent() {
			this.cultures.map((c, key) => {
				this.votes.filter((v) => {
					if (v.culture_element_id == c.id) {
						this.cultures[key].count
							? this.cultures[key].count++
							: this.$set(this.cultures[key], "count", 1);
					} else if (!this.cultures[key].count) {
						this.$set(this.cultures[key], "count", 0);
					}
				});
			});
		},
		scrollDown() {
			const scrollHeight = this.$refs.culturesList?.scrollHeight;
			this.$refs.culturesList?.scrollTo(0, scrollHeight);
		},
		selectCulture(culture) {
			this.modal.title = culture.name;
			this.modal.message = culture.feedback;
			this.toggleModal();
		},
		toggleModal() {
			this.modal.show = !this.modal.show;
		},
		clearErrors() {
			this.setUserError(null);
			this.setRankError(null);
			this.setCulturesError(null);
			this.setPhotoError(null);
		},
	},
};
</script>
<template>
	<div class="h-full flex flex-col justify-between items-center">
		<modal
			@click.native="toggleModal"
			v-if="modal.show"
			buttonText="Continuar"
		>
			<p class="text-white text-center">
				<span class="b yellow block text-xl">{{ modal.title }}</span>
				<span class="text-lg">{{ modal.message }}</span>
			</p></modal
		>

		<div class="image relative z-10 flex-none mt-6">
			<div
				class="absolute right-1/2 bottom-0 transform translate-x-1/2 blue bg-gray-light user-avatar rounded-3xl flex justify-center items-center mx-auto overflow-hidden"
			>
				
				<img
					
					class="block w-full h-full object-cover transform"
					:src="'profile.png'"
					alt="Trofeo"
				/>
			</div>

			<label for="imageCapture" class="relative cursor-pointer">
				<input
					@change="loadImage($event)"
					id="imageCapture"
					class="opacity-0 absolute -z-10 w-full bottom-1/2 h-0"
					type="file"
					accept="image/*"
					capture
				/>
				<img
					class="block h-full object-contain m-auto"
					:src="getImage('profile_bg.png')"
					alt="Trofeo"
				/>
			</label>
		</div>
		<div
			class="text w-full mb-4 pt-20 pb-4 flex flex-col justify-between mx-auto rounded-2xl flex-none"
		>
			<p v-if="!isLoading && user" class="text-center p-3 text-white">
				<span class="block text-xl">{{
					!user.name || user.basic_info
						? `${user.first_name} ${user.last_name}`
						: user.name
				}}</span>
				<!-- <span class="block text-md b">{{
					user.work_position
				}}</span> -->
			</p>
			<p v-else-if="listed" class="text-center p-3 text-white">
				<span class="block text-lg">No se encontró tu perfil</span>
			</p>
			<div class="flex justify-center">
				<div class="flex relative items-center">
					<div
						class="absolute transform -translate-x-1/4 icon vote rounded-full"
					>
						<img
							class="transform scale-110 -translate-x-0.5 -translate-y-1"
							:src="getImage('voto_h.png')"
							alt="Corazon en mano"
						/>
					</div>
					<span
						class="scores py-1 pl-8 pr-6 rounded-full text-md blue"
					>
						Reconocimientos
						<span class="b elektra-red">{{ rank.votes }}</span></span
					>
				</div>
			</div>
		</div>
		<div
			class="mb-2 flex flex-col justify-between overlay bg-white mx-auto flex-grow"
		>
			<p class="text-center text-lg pt-3 px-5 flex-none">
				Así <span class="b">vives</span> nuestra
				<span class="b elektra-red">Cultura:</span>
			</p>
			<scroll-button
				class="fixed z-10 right-2 bottom-1/4"
				@click.native="scrollDown"
			></scroll-button>
			<div
				ref="culturesList"
				class="cultures-list flex flex-wrap w-full px-5 py-3 overflow-y-scroll"
			>
				<div
					@click="selectCulture(c)"
					v-show="c.active"
					v-for="c in orderedArray(cultures)"
					:key="c.id"
					class="culture-wrap flex justify-between items-center w-full oveflow-visible mb-3 cursor-pointer"
				>
					<div
						class="culture flex-none mx-2 block icon-link rounded-full flex items-center justify-center"
					>
						<span class="block percentage text-sm flex">
							{{
								c.count > 0
									? Math.round((c.count / rank.votes) * 100)
									: 0
							}}<span class="small">%</span>
						</span>
					</div>
					<div class="label flex-grow">
						<span
							class="w-full flex flex-col text-left text-md b blue"
							>{{ c.name }}</span
						>
					</div>
				</div>
			</div>

			<Nav-bar-votacion :active="this.$route.name"></Nav-bar-votacion>
			<Footer class="static"></Footer>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.image {
	height: 25vh;
	margin-bottom: -5.5rem;
}

.overlay {
	height: 35vh;
}

.culture {
	border: 2px solid #d6e2f0;
	width: 38px;
	height: 38px;
	box-shadow: 0px 7px 14px rgba($color: #7ca9bd, $alpha: 0.75);
	overflow: visible;

	&-wrap {
		&:nth-child(1) {
			// width: 100%;
			// background: linear-gradient(to right, #d6e2f0, #f2f6ff);
			// border-radius: get-vh(66px);

			// .label {
			// 	overflow: hidden;
			// 	width: 85%;

			// 	span {
			// 		width: 100%;
			// 		text-overflow: ellipsis;
			// 		white-space: nowrap;
			// 		align-items: flex-start;
			// 		font-size: get-vh(50px);
			// 	}
			// }

			.culture {
				border: none;
				color: #fff;
				position: relative;
				background: none;
				z-index: 1;
				border-color: transparent;
				overflow: visible;

				&::before {
					content: "";
					display: block;
					width: 38px;
					height: 38px;
					position: absolute;
					right: 50%;
					bottom: 50%;
					transform: translate(50%, 50%);
					background: linear-gradient(270deg, #0D6543, #05462d);
					border-radius: 50%;
					z-index: -1;
				}
				&::after {
					content: "";
					display: block;
					width: 32px;
					height: 32px;
					position: absolute;
					right: 50%;
					bottom: 50%;
					transform: translate(50%, 50%);
					background: linear-gradient(to right, #0D6543, #0D6543);
					border-radius: 50%;
					z-index: 1;
				}

				.percentage {
					position: relative;
					z-index: 2;
				}
			}
		}
	}
}

.icon {
	width: 40px;
	height: 40px;
	background: linear-gradient(to right, #ffb800, #ffb800 36%, #fb5b00);
	border: 2px solid #f2f6ff;
	box-shadow: 0px 0px 12px rgba($color: #224c57, $alpha: 0.35);
}

.user-avatar {
	width: get-vh(520px);
	height: get-vh(520px);
	font-size: get-vh(520px);
	border: get-vh(20px) solid #e0e0e0;
	margin-bottom: get-vh(150px);
}

.text {
	height: 30vh;
	max-height: 180px;
	width: calc(100% - 1rem);
	background: radial-gradient(
			200% 200% at 100% 0%,
			#d9291b,
			#a41006 45%,
			#540702
	);
}

.small {
	font-size: 0.4rem;
}

.scores {
	background: linear-gradient(to right, #d6e2f0, #f2f6ff);
}
</style>
